import { useNavigate } from '@remix-run/react';
import { RiCloseLine, RiLogoutBoxLine } from '@remixicon/react';
import { Dispatch, MutableRefObject, SetStateAction } from 'react';
import { Button } from '~/components/ui/button';
import { useClickAway } from '@uidotdev/usehooks';
import { useTranslation } from 'react-i18next';
import { FAQLinks } from './faq';
import { NavLinks } from './nav-links';
import { AccountInfo } from './account';
import { clearSession } from '~/utils/localStorage';
import { cn } from '~/utils/tw';

export type SidebarProps = {
  showSidebar: boolean;
  setShowSidebar: Dispatch<SetStateAction<boolean>>;
};

export default function Sidebar({ showSidebar, setShowSidebar }: SidebarProps) {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const ref = useClickAway(() => {
    setShowSidebar(false);
  }) as MutableRefObject<HTMLInputElement>;

  const onClickHandler = () => {
    if (showSidebar) setShowSidebar(false);
  };

  const onLogoutHandler = () => {
    clearSession();
    navigate('/login');
  };

  return (
    <div>
      {/* Custom Sidebar Backdrop */}
      <div
        className={cn(
          'absolute left-0 top-0 z-40 h-screen w-full bg-black/50',
          showSidebar ? 'block sm:hidden' : 'hidden',
        )}
      />
      <aside
        ref={ref}
        id="logo-sidebar"
        className={cn(
          `group fixed left-0 top-0 z-50 flex h-screen w-[16rem] flex-col bg-white pb-4 shadow-md transition-all hover:xs:w-[16rem] sm:z-40 sm:w-[6.563rem] sm:translate-x-0 sm:pt-16 hover:sm:w-[16rem] lg:w-[16rem]`,
          !showSidebar ? '-translate-x-full' : '',
        )}
        aria-label="Sidebar"
      >
        <div className="flex flex-grow flex-col gap-6 px-6 py-4">
          <Button
            size="icon"
            onClick={() => setShowSidebar(false)}
            className="bg-transparent hover:bg-transparent hover:shadow-none sm:hidden"
          >
            <RiCloseLine className="h-full w-full text-homy-gray-darker" />
          </Button>
          <AccountInfo />
          <div className="py flex flex-grow flex-col items-start gap-4 overflow-hidden">
            <p className="text-xs uppercase text-homy-gray-light">
              {t('labels.menu')}
            </p>
            <NavLinks
              showSidebar={showSidebar}
              onClickHandler={onClickHandler}
            />
            <Button
              variant="navigation"
              className="mt-auto sm:hidden"
              onClick={onLogoutHandler}
            >
              <RiLogoutBoxLine className="h-6 w-6 text-destructive" />
              <p className="text-destructive">{t('labels.logout')}</p>
            </Button>
          </div>
        </div>
        <FAQLinks />
      </aside>
    </div>
  );
}
