import {
  RiLogoutBoxLine,
  RiMenu4Line,
  RiUserSettingsLine,
} from '@remixicon/react';
import { Button } from '~/components/ui/button';
import { Dispatch, SetStateAction } from 'react';
import { useNavigate } from '@remix-run/react';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '~/components/ui/popover';
import { useTranslation } from 'react-i18next';
import { clearSession, getUser } from '~/utils/localStorage';
import NotificationsBtn from './notifications-btn';
import { useCurrentUserQuery } from '~/hasura/__generated__';
import { getInitials } from '~/utils/name';
import { prefixUrlWithRole } from '~/utils/navigation';
import { Logo } from '~/components/logo';

export type SidebarProps = {
  showSidebar?: boolean;
  setShowSidebar?: Dispatch<SetStateAction<boolean>>;
};

export default function Header({ showSidebar, setShowSidebar }: SidebarProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data } = useCurrentUserQuery();
  const { role } = getUser();

  const user = data?.getCurrentUser;

  const onLogoutHandler = () => {
    clearSession();
    navigate('/login');
  };

  return (
    <nav className="fixed top-0 z-40 h-16 w-full bg-white bg-gradient-to-r from-primary/5 px-6 shadow-sm sm:z-50">
      <div className="flex h-full items-center justify-between">
        <Button
          size="icon"
          onClick={() => setShowSidebar?.(!showSidebar)}
          className="bg-transparent hover:bg-transparent hover:shadow-none sm:hidden"
        >
          <RiMenu4Line size={24} className="text-homy-gray-darker" />
        </Button>
        <Logo />
        <div className="flex flex-row items-center gap-8">
          {role && <NotificationsBtn />}
          <Popover>
            <PopoverTrigger className="hidden h-10 w-10 items-center justify-center rounded-3xl border-2 border-white bg-homy-green-dark text-base text-white shadow-md sm:flex">
              {getInitials(user?.name || '')}
            </PopoverTrigger>
            <PopoverContent>
              <Button
                variant="navigation"
                onClick={() => navigate(prefixUrlWithRole('/settings/general'))}
                className="px-4 py-3 hover:border-none"
              >
                <RiUserSettingsLine
                  size={16}
                  className="text-homy-gray-darker"
                />
                <p className="text-homy-gray-darker">
                  {t('links.profileSettings')}
                </p>
              </Button>
              <Button
                variant="navigation"
                onClick={onLogoutHandler}
                className="px-4 py-3 hover:border-none"
              >
                <RiLogoutBoxLine size={16} className="text-destructive" />
                <p className="text-destructive">{t('labels.logout')}</p>
              </Button>
            </PopoverContent>
          </Popover>
        </div>
      </div>
    </nav>
  );
}
